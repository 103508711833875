import { logEvent } from "firebase/analytics";
import ContactForm from "../../components/ContactForm";
import FinvisageSection from "../../components/FinvisageSection";
import FinvisageVideo from "../../components/FinvisageVideo";
import Testimonials from "../../components/Testimonials";
import { analytics } from "../../firebase.config";

import HomeHero from "./HomeHero";

const Home = () => {
  const sendLogsToGA = () => {
    logEvent(analytics, "link_click", {
      link_label: "Home_form_requesttrial_button",
      location: "home_page",
    });
  };

  const sendLogsToGASignUpHome = () => {
    logEvent(analytics, "link_click", {
      link_label: "Home_form_signup_button",
      location: "home_page",
    });
  };

  return (
    <div>
      <HomeHero />
      <FinvisageSection />
      <FinvisageVideo />
      <ContactForm
        sendLogsToGA={sendLogsToGA}
        sendLogsToGASignUp={sendLogsToGASignUpHome}
      />
      <Testimonials />
    </div>
  );
};

export default Home;
