const FinvisageSection = () => {
  return (
    <>
      <section>
        <main className=" bg-custom-gradient  sx:px-3 rounded-xl m-auto py-10">
          <div className="md:flex   ">
            <div className="flex-1  flex justify-center " data-aos="zoom-in">
              <img
                src="/image/laptopScreen.webp"
                alt="screenImg"
                className="sx:w-full md:w-3/4"
              />
            </div>

            <div
              className="flex-1 sx:items-center flex sx:mt-6  flex-col  justify-center md:items-start  "
              data-aos="zoom-in"
            >
              <div className="flex flex-col sx:gap-y-14 ">
                <h1 className="md:text-4xl sx:text-2xl text-FinvisageText sx:text-center md:text-start font-bold">
                  <span className="text-black  font-normal italic">
                    What is
                  </span>{" "}
                  Finvisage<span className="font-bold text-black "> ?</span>{" "}
                </h1>
                <div className=" md:text-2xl sx:text-base font-medium leading-loose">
                  <h1>Finvisage is a derivative valuation solution, </h1>{" "}
                  <h1>developed by world-class traders & market</h1>experts, now
                  available as SaaS.
                </div>
              </div>
              <div className="mt-12 ">
                <a
                  href="#demo"
                  className=" text-white  sx:text-base sm:text-xl   font-medium sx:px-10 px-14 py-3 rounded-full hover:bg-FinvisageText bg-blue-600 "
                >
                  Sign up & start pricing
                </a>
              </div>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};
export default FinvisageSection;
