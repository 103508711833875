import React from "react";

const WhatsappFeature = () => {
  const phoneNumber = "919895600372";
  const message = "Hello. I am interested in knowing more about Finvisage.";
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <div className="fixed bottom-4 left-2 z-50">
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        <button className="flex flex-col items-center gap-y-2  pt-2 pb-6 px-3 text-lg rounded-md  transition-colors">
          <span className="text-sm text-white bg-[#4A4A4A] px-2 py-1 rounded-full">
            Chat with us
          </span>
          <i className="fa-brands fa-square-whatsapp fa-2xl mt-3 text-[#25D366] "></i>
        </button>
      </a>
    </div>
  );
};

export default WhatsappFeature;
