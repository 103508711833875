import { scrollTop } from "../../config/Constant";
import { useEffect } from "react";
const CorporateHome = ({ CorporateData }) => {
  useEffect(() => {
    setTimeout(() => {
      scrollTop();
    }, 300);
  }, []);

  return (
    <main
      id="corporates"
      className="bg-cover bg-center  w-full      "
      style={{ backgroundImage: "url(/image/corporateBg.webp)" }}
    >
      <div
        className=" w-full sx:pt-6  mt-14 sx:px-3 md:px-6 md:pt-16 pb-8 pr-4 "
        data-aos="zoom-in"
      >
        <img
          src={CorporateData.imgSrc}
          alt="Corporate Logo"
          className=" sx:w-40  md:w-60   "
        />
        <h1 className="sm:text-xl sx:base tracking-widest pt-2  text-white font-semibold">
          {CorporateData.service}
        </h1>
        <div className="flex flex-col gap-y-3 mt-10 ">
          {CorporateData.texts.map((text, index) => (
            <p
              key={index}
              className={`text-white font-medium sx:text-sm   sm:text-lg ${
                index === CorporateData.texts.length - 1
                  ? " sx:font-extrabold "
                  : ""
              }`}
            >
              {text}
            </p>
          ))}
        </div>
        <div>
          <div className="mt-6">
            <a
              href="#demo"
              className=" hover:bg-FinvisageText text-white py-2 px-4 rounded bg-blue-600 transition duration-300"
            >
              Request a Free Trial
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CorporateHome;
