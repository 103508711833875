import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase.config";

const usePageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_title: location.pathname,
      page_location: location.pathname,
    });
  }, [location]);
};

export default usePageViewTracking;
