import { logEvent } from "firebase/analytics";
import ContactForm from "../../components/ContactForm";
import FinvisageVideo from "../../components/FinvisageVideo";
import CorporateFaq from "./CorporateFaq";
import CorporateHome from "./CorporateHome";
import { analytics } from "../../firebase.config";

const Corporates = ({ data }) => {
  const sendLogsToGA = () => {
    logEvent(analytics, "link_click", {
      link_label: "corporates_form_requesttrial_button",
      location: "corporates_page",
    });
  };

  const sendLogsToGASignUpCorporate = () => {
    logEvent(analytics, "link_click", {
      link_label: "corporate_signup_button",
      location: "corporate_page",
    });
  };

  return (
    <div>
      <CorporateHome CorporateData={data.subSection[0]} />
      <CorporateFaq
        title={data.midSection[0].title}
        BanksQNA={data.midSection[0].QA}
      />
      <FinvisageVideo
        title={data.corporates.title}
        videoId={data.corporates.videoLink}
      />

      <ContactForm
        sendLogsToGA={sendLogsToGA}
        sendLogsToGASignUp={sendLogsToGASignUpCorporate}
      />
    </div>
  );
};

export default Corporates;
