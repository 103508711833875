

import { BrowserRouter as Router, } from 'react-router-dom';
import Header from './components/Header'; 
import Footer from './components/Footer';
import AppRoutes from './routes/Routing';
import '@fortawesome/fontawesome-free/css/all.min.css';
import WhatsappFeature from './components/WhatsappFeature';
import Clarity from '@microsoft/clarity';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    const projectId =  process.env.REACT_APP_CLARITY_PROJECT_ID; 
    Clarity.init(projectId); 
  }, []);
 
  return (
    
      <Router>
        <Header />
        <WhatsappFeature />
        <AppRoutes />
        <Footer />
      </Router>

    
  );
};

export default App;
