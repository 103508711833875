const FinvisageVideo = ({
  title = "",
  videoId = "dkSUzv1JAlc",
  sendLogsToGAFxVideo,
}) => {
  const onHandleClick = () => {
    sendLogsToGAFxVideo();
  };

  return (
    <>
      <main>
        <div
          className="bg-cover bg-center max-h-fit  sx:py-8 sm:py-20"
          style={{ backgroundImage: "url(/image/videoBg.webp)" }}
        >
          <div
            className="flex flex-col justify-center sx:px-3 gap-y-20 max-w-6xl mx-auto "
            data-aos="zoom-in"
          >
            <div>
              {!title && (
                <h1 className="sm:text-3xl sx:text-base sx:font-bold sm:font-medium text-white text-center ">
                  Watch a quick <span className="underline">video</span> to
                  understand how <span className="underline">Finvisage</span>{" "}
                  works for you
                </h1>
              )}
              {title && (
                <h1 className="sm:text-3xl sx:font-bold sx:text-base sm:font-medium text-white text-center ">
                  {title}
                </h1>
              )}
            </div>
            <div className="flex justify-center" onClick={onHandleClick}>
              <div className="w-full md:w-4/5  ">
                <iframe
                  className="w-full h-64 md:h-80 lg:h-[500px] border-none rounded-lg shadow-lg"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FinvisageVideo;
