import { logEvent } from "firebase/analytics";
import ContactForm from "../../components/ContactForm";
import FinvisageVideo from "../../components/FinvisageVideo";
import CorporateFaq from "../Corporates/CorporateFaq";
import CorporateHome from "../Corporates/CorporateHome";
import { analytics } from "../../firebase.config";

const ForexConsultants = ({ data }) => {
  const sendLogsToGA = () => {
    logEvent(analytics, "link_click", {
      link_label: "fx_form_requesttrial_button",
      location: "fx_page",
    });
  };
  const sendLogsToGAFxVideo = () => {
    logEvent(analytics, "link_click", {
      link_label: "fx_video_link",
      location: "fx_page",
    });
  };

  return (
    <div>
      <CorporateHome CorporateData={data.subSection[1]} />
      <CorporateFaq
        title={data.midSection[1].title}
        BanksQNA={data.midSection[1].QA}
      />

      <FinvisageVideo
        title={data.fx.title}
        videoId={data.fx.videoLink}
        sendLogsToGAFxVideo={sendLogsToGAFxVideo}
      />
      <ContactForm hide={true} sendLogsToGA={sendLogsToGA} />
    </div>
  );
};

export default ForexConsultants;
