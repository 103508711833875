const { pricingitems } = require("../config/Constant");

const StartPricing = ({ sendLogsToGASignUp }) => {
  const handleClick = () => {
    sendLogsToGASignUp();
  };

  return (
    <>
      <div className="container mx-auto sx:px-3 md:px-12 py-12">
        <div className="flex flex-col lg:gap-y-11 justify-center items-center">
          <div>
            <h1 className="sx:text-2xl sm:text-4xl font-bold text-center text-PricingText my-4">
              Sign up & start pricing
            </h1>
          </div>

          <div className="flex flex-col gap-4">
            {pricingitems.map((item, index) => (
              <div key={index} className="flex items-center my-2 py-3">
                <img
                  src={item.imgSrc}
                  alt="Checkmark"
                  className="w-5 h-5 sm:w-6 sm:h-6 mr-2"
                />
                <p className="text-PricingText sx:text-base sx:font-medium sm:text-xl sm:font-semibold">
                  {item.text}
                </p>
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-4">
            <a
              href="https://derivpricer.finvisage.com/users/sign_in"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white sm:text-xl sx:text-base font-medium px-10 py-3 rounded-full hover:bg-FinvisageText bg-blue-600"
              onClick={handleClick}
            >
              Sign up & start pricing
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default StartPricing;
