import { useState } from "react";
import { Link } from "react-router-dom";
import { HeaderItems } from "../config/Constant";
import { analytics } from "../firebase.config";
import { logEvent } from "firebase/analytics";
import usePageViewTracking from "../customhooks/usePageViewTracking";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  usePageViewTracking();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleHeaderLinkClick = (linkLabel, location) => {
    logEvent(analytics, "link_click", {
      link_label: linkLabel,
      location: location,
    });
  };

  const mobileMenuItems = [
    ...HeaderItems.column1,

    {
      label: "Log in",
      path: "https://derivpricer.finvisage.com/users/sign_in",
      isExternal: true,
    },
    {
      label: "Sign Up",
      path: "https://derivpricer.finvisage.com/workspaces",
      isExternal: true,
    },
  ];

  return (
    <section className="bg-FooterBg ">
      <div
        id="header"
        className="flex items-center justify-between md:px-6 py-4 fixed top-0 left-0 w-full z-50 bg-FooterBg   sx:px-3 "
      >
        <div
          className="lg:hidden sx:flex-shrink-0 text-white cursor-pointer"
          onClick={toggleMenu}
        >
          <i className="fa-solid fa-bars fa-xl"></i>
        </div>

        <div className=" sx:flex-grow sm:flex-none sm:text-left">
          <Link to="/">
            <img
              src="/image/transparentLogo.webp"
              alt="Logo"
              className="w-48 mx-auto sm:mx-0 "
            />
          </Link>
        </div>

        <div className="hidden lg:flex ">
          <ul className="flex justify-start gap-8">
            {HeaderItems.column1.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  className="hover:underline hover:text-blue-400 font-normal text-white"
                  onClick={() =>
                    handleHeaderLinkClick(item.link_label, "header")
                  }
                >
                  {item.label}
                </Link>
              </li>
            ))}

            <a
              href="#demo"
              className="hover:underline hover:text-blue-400 font-normal text-white"
              onClick={() =>
                handleHeaderLinkClick("Header_contact_us", "header")
              }
            >
              Contact Us
            </a>
          </ul>
        </div>

        <div className="hidden lg:flex space-x-4 text-white">
          <a
            href="https://derivpricer.finvisage.com/users/sign_in"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-1 rounded-3xl hover:bg-LoginBtnColor shadow transition-shadow duration-200 hover:shadow-lg hover:shadow-gray-500"
            onClick={() => handleHeaderLinkClick("Header_login_link", "header")}
          >
            Log in
          </a>
          <a
            href="https://derivpricer.finvisage.com/workspaces"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-1 rounded-3xl hover:bg-LoginBtnColor shadow transition-shadow duration-200 hover:shadow-lg hover:shadow-gray-500"
            onClick={() =>
              handleHeaderLinkClick("Header_signup_link", "header")
            }
          >
            Sign Up
          </a>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 w-3/4 h-full  bg-FooterBg text-white p-6 transition-transform duration-300 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } z-50`}
      >
        <div className="flex justify-between items-center mb-6">
          <div className="cursor-pointer" onClick={toggleMenu}>
            <i className="fa-solid fa-xmark fa-xl"></i>
          </div>
          <Link to="/" onClick={toggleMenu}>
            <img
              src="/image/transparentLogo.webp"
              alt="Logo"
              className="w-40 mx-auto"
            />
          </Link>
        </div>

        <ul className="flex flex-col   gap-6">
          {mobileMenuItems.map((item, index) => (
            <li key={index} onClick={toggleMenu} className="p-1">
              {item.isExternal ? (
                <a
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:underline hover:text-blue-400 text-base  font-semibold"
                >
                  {item.label}
                </a>
              ) : (
                <Link
                  to={item.path}
                  className="text-white text-base hover:text-blue-400   font-semibold"
                >
                  {item.label}
                </Link>
              )}
            </li>
          ))}

          <li onClick={toggleMenu} className="p-1">
            <a
              href="#demo"
              className="text-white text-base hover:text-blue-400 font-semibold"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Header;
