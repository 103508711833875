import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { footerItems, scrollTop } from "../config/Constant";
import { analytics } from "../firebase.config";
import { logEvent } from "firebase/analytics";

const Footer = () => {
  const handleLinkClick = (linkLabel, location) => {
    logEvent(analytics, "link_click", {
      link_label: linkLabel,
      location: location,
    });
  };

  const handleLogoClick = () => {
    scrollTop();
  };

  useEffect(() => {
    setTimeout(() => {
      scrollTop();
    }, 300);
  }, []);

  return (
    <footer className="bg-FooterBg sx:px-3 md:px-6 py-9 font-inter text-FooterText">
      <div className="flex flex-col gap-y-12">
        <div className="lg:flex justify-between items-start">
          <div className="sm:w-full lg:w-5/12" onClick={handleLogoClick}>
            <Link to="/">
              <img
                src="/image/transparentLogo.webp"
                alt="Logo"
                className="sx:w-56 md:w-64"
              />
            </Link>
          </div>
          <div className="sx:pt-4 flex flex-grow space-x-6">
            <div className="flex-1">
              <h4 className="font-bold mb-4">Solutions</h4>
              <ul>
                {footerItems.column1.map((item, index) => (
                  <li key={index} className="sx:mb-3 mb-1">
                    <Link
                      to={item.path}
                      className="hover:underline hover:text-blue-400 font-normal"
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex-1">
              <h4 className="font-bold mb-4">Contact Us</h4>
              <ul>
                {footerItems.column2.map((item, index) => (
                  <li key={index} className="sx:mb-3 mb-1">
                    {item.url ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline hover:text-blue-400 font-normal"
                      >
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        className="hover:underline hover:text-blue-400 font-normal"
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <a
                href="#demo"
                className="hover:underline hover:text-blue-400 font-normal"
              >
                Request a Free Trial
              </a>
            </div>

            <div className="flex-1">
              <h4 className="font-bold mb-4">Links</h4>
              <ul>
                {footerItems.column3.map((item, index) => (
                  <li key={index} className="sx:mb-3 mb-1">
                    {item.url ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline hover:text-blue-400 font-normal"
                        onClick={() =>
                          handleLinkClick(item.link_label, "footer")
                        }
                      >
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        className="hover:underline hover:text-blue-400 font-normal"
                        onClick={() =>
                          handleLinkClick(item.link_label, "footer")
                        }
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="">
          <h1 className="text-center hover:text-blue-400 text-sm">
            Copyright © 2024 Finvisage All Rights Reserved
          </h1>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
