import { logEvent } from "firebase/analytics";
import ContactForm from "../../components/ContactForm";
import Testimonials from "../../components/Testimonials";
import { analytics } from "../../firebase.config";
import CorporateFaq from "../Corporates/CorporateFaq";
import CorporateHome from "../Corporates/CorporateHome";

const Banks = ({ data }) => {
  const sendLogsToGA = () => {
    logEvent(analytics, "bank_link_click", {
      link_label: "bank_form_requesttrial_button",
      location: "bank_page",
    });
  };

  return (
    <div>
      <CorporateHome CorporateData={data.subSection[2]} />
      <CorporateFaq
        title={data.midSection[2].title}
        BanksQNA={data.midSection[2].QA}
      />

      <Testimonials />
      <ContactForm hide={true} sendLogsToGA={sendLogsToGA} />
    </div>
  );
};

export default Banks;
