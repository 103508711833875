import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CorporateHomePageData,
  ForexConsultantsHomePageData,
  BanksHomePageData,
} from "../../config/Constant";

const HomeHero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();

  const dataArray = [
    CorporateHomePageData,
    ForexConsultantsHomePageData,
    BanksHomePageData,
  ];
  const routes = ["/corporates", "/forex-consultants", "/banks"];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % dataArray.length);
        setFade(false);
      }, 500);
    }, 6000);

    return () => clearInterval(interval);
  }, [dataArray.length]);

  const handleIndicatorClick = (index) => {
    setFade(true);

    setTimeout(() => {
      setCurrentIndex(index);
      setFade(false);
    }, 500);
  };

  const handleClickMore = () => {
    navigate(routes[currentIndex]);
  };

  const currentData = dataArray[currentIndex];

  return (
    <>
      <main
        className="relative   bg-cover bg-center sx:h-[700px] md:h-[600px] w-full "
        id="top"
        style={{ backgroundImage: "url(/image/homePageBg.webp)" }}
      >
        <div className="px-3 md:pt-16 sx:pt-6   md:px-6 h-full">
          <div
            className={`flex mt-12 flex-col md:flex-row gap-6 transition-opacity duration-500 ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          >
            <div className="flex-1">
              <img
                src={currentData.imgSrc}
                alt={`${currentData.service} Logo`}
                className=" sx:w-40  md:w-60 "
              />
              <h1 className=" sm:text-xl sx:text-base tracking-widest pt-2 text-white font-semibold text-left">
                {currentData.service}
              </h1>
              <div className="flex flex-col gap-y-3 mt-4">
                {currentData.texts.map((text, index) => (
                  <p
                    key={index}
                    className={`text-white font-medium sx:text-sm md:text-lg text-left ${
                      index === currentData.texts.length - 1
                        ? " sx:font-extrabold "
                        : ""
                    } `}
                  >
                    {text}
                  </p>
                ))}
              </div>
              <button
                onClick={handleClickMore}
                className=" text-white sx:text-sm sm:text-lg py-2 px-4 rounded sx:mt-3 sm:my-6 mx-auto lg:mx-0 hover:bg-FinvisageText  bg-blue-600 transition duration-300"
              >
                Click here to know more
              </button>
            </div>
            <div className="flex-1  ">
              <img
                src={currentData.imgSrc2}
                alt="Carousel"
                className="w-full  sx:w-full sm:max-w-xl  md:mx-auto"
              />
            </div>
          </div>
        </div>

        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex justify-center space-x-2">
          {dataArray.map((_, index) => (
            <button
              key={index}
              onClick={() => handleIndicatorClick(index)}
              className={`w-2 h-2 rounded-full ${
                currentIndex === index ? "bg-blue-600" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </main>
    </>
  );
};

export default HomeHero;
